@import "../constants.scss";

/* Sidebar Search */
.changed-file-tree-search {
  margin-bottom: 8px;
  margin-right: 22px;
}

.changed-file-tree-download-zip-button {
  margin-bottom: 16px;
  box-shadow: none;
}

/* File Tree */
.changed-file-tree {
  overflow-y: auto;
  overflow-x: auto;
  background-color: transparent;
}

.changed-file-tree .ant-tree-node-content-wrapper:hover {
  background-color: rgba(64, 154, 212, 0.1);
}

.changed-file-tree .ant-tree-node-selected {
  background-color: rgba(64, 154, 212, 0.2) !important;
}

.changed-file-tree .changed-file-tree-node-title {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.changed-file-tree .changed-file-tree-node-text {
  max-width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: $TEXT_FONT_FAMILY;
  color: #e2e2e2;
}

.changed-file-tree .ant-tree-switcher {
  width: auto;
  margin-inline-end: 8px;
}

/* Reset padding for top-level nodes */
.changed-file-tree > .ant-tree-list > .ant-tree-list-holder > div > .ant-tree-list-holder-inner > .ant-tree-treenode {
  padding-left: 0 !important;
}
