.tech-plan {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.tech-plan-error {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}

.tech-plan-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--peripheral-content-background-color);
}

.tech-plan-loading {
  opacity: 0.7;
  pointer-events: none;
}

.tech-plan-task {
  border-radius: 4px;
  transition: all 0.3s ease;
}

.tech-plan-task:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}

.tech-plan-task-header {
  display: flex;
  align-items: center;
  gap: 8px;
  transition: background-color 0.3s ease;
}

.tech-plan-task-section {
  margin-bottom: 24px;
}

.tech-plan-task-section:last-child {
  margin-bottom: 0;
}

.tech-plan-task-section-title {
  font-weight: 600;
  margin-bottom: 12px;
  color: var(--solver-blue);
}

.tech-plan-question {
  background-color: var(--peripheral-content-background-color);
  padding: 16px;
  border-radius: 4px;
  margin-bottom: 16px;
  border: 1px solid var(--main-content-background-color);
}

.tech-plan-question:last-child {
  margin-bottom: 0;
}

.tech-plan-question-text {
  font-weight: 500;
  margin-bottom: 16px;
}

.tech-plan-answer-input {
  margin-bottom: 16px;
  background-color: var(--input-background-color);
  transition: all 0.3s ease;
  border: 1px solid;
}

.tech-plan-answer-input:hover {
  border-color: var(--solver-blue);
}

.tech-plan-answer-input.focused {
  border-color: var(--solver-blue);
  box-shadow: 0 0 0 2px rgba(64, 154, 212, 0.2);
}

.tech-plan-answer-input.answered {
  border-color: #52c41a;
  background-color: rgba(82, 196, 26, 0.05);
}

.tech-plan-answer-input.answered:hover {
  border-color: #52c41a;
  box-shadow: 0 0 0 2px rgba(82, 196, 26, 0.1);
}

.tech-plan-answer-input.answered:not(.focused) {
  resize: none;
}

.tech-plan-dependencies {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
}

.tech-plan-dependency-tag {
  background-color: var(--main-content-background-color);
  color: var(--solver-blue);
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 0.9em;
  border: 1px solid var(--solver-blue);
}

/* Animation for collapse/expand */
.tech-plan-task-content {
  transition: all 0.3s ease-in-out;
  opacity: 1;
}

.tech-plan-collapsed .tech-plan-task-content {
  max-height: 0;
  padding-top: 0;
  padding-bottom: 0;
  opacity: 0;
  margin: 0;
}

.tech-plan-task-header {
  cursor: pointer;
}

.tech-plan-task-header:hover {
  background-color: var(--main-content-background-color);
}

.tech-plan-tasks-empty {
  text-align: center;
  padding: 40px;
  background-color: var(--peripheral-content-background-color);
  border-radius: 8px;
  border: 1px dashed var(--main-content-background-color);
}

/* Tasks header */
.tech-plan-tasks-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.tech-plan-tasks-header .ant-btn {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Task status indicators */
.tech-plan-task {
  position: relative;
  background-color: var(--peripheral-content-background-color);
  border: 1px solid transparent;
  transition: all 0.3s ease;
}

.tech-plan-task:hover {
  border-color: var(--solver-blue);
}

.tech-plan-task::before {
  content: "";
  position: absolute;
  left: -16px;
  top: 0;
  bottom: 0;
  width: 3px;
  background-color: var(--solver-blue);
  border-radius: 3px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.tech-plan-task:hover::before {
  opacity: 1;
}

/* Question styling */
.tech-plan-question {
  position: relative;
  border: 1px solid var(--peripheral-content-background-color);
  transition: border-color 0.3s ease;
}

.tech-plan-question:hover {
  border-color: var(--solver-blue-hover);
}

.tech-plan-question-text {
  color: inherit;
  font-size: 14px;
  line-height: 1.5715;
}

.tech-plan-answer-input {
  border-color: var(--peripheral-content-background-color);
  transition: all 0.3s ease;
}

.tech-plan-answer-input:hover {
  border-color: var(--solver-blue-hover);
}

.tech-plan-answer-input:focus {
  border-color: var(--solver-blue);
  box-shadow: 0 0 0 2px rgba(64, 154, 212, 0.2);
}

/* Styling for project-level questions */
.tech-plan-project-questions {
  border-radius: 8px;
  border: 1px solid var(--main-content-background-color);
  margin-bottom: 24px;
}

.tech-plan-project-questions-title {
  font-size: 1.2em;
  font-weight: 600;
  margin-bottom: 16px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .tech-plan {
    padding: 12px;
  }

  .tech-plan-task {
    margin-bottom: 12px;
  }
}
