.solve-button-group {
  display: flex;
  align-items: stretch;

  .ant-btn {
    height: auto;

    &:first-child {
      padding: 6.5px 15px;
    }
  }
}

.credit-controls-wrapper {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: flex-end;
  text-align: right;
}

.credit-controls-error {
  color: rgba(255, 255, 255, 0.65); // Using a muted white color for info
  font-size: 12px;
  line-height: 1.5;
  margin-top: -4px; // Bring it slightly closer to the button
  display: flex;
  align-items: center;
  gap: 4px;
  .ant-btn-link {
    font-size: 12px;
    padding: 0;
    height: auto;
    line-height: 1.5;
    &:hover {
      color: lighten(#ff4d4f, 10%);
      opacity: 0.85;
    }
  }
}

.credit-controls-info {
  color: rgba(255, 255, 255, 0.65); // Using a muted white color for info
  font-size: 12px;
  line-height: 1.5;
  margin-top: -4px; // Bring it slightly closer to the button
}

.effort-indicator {
  display: flex;
  align-items: center;
  gap: 2px;
  height: 16px;
}

.effort-bar {
  width: 4px;
  height: 16px;
  border-radius: 2px;
}

.filled.light {
  background-color: black;
}
.empty {
  background-color: #e5e7eb;
}

.filled.dark {
  background-color: white;
}
.empty.dark {
  background-color: #4b5563;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.disabled .filled {
  background-color: #a0a0a0 !important; /* Gray instead of normal filled color */
}
