.editable-textarea {
  &.ant-input-textarea {
    textarea.ant-input {
      transition: all 0.3s;

      &:hover {
        border-color: #177ddc !important;
      }

      &:focus {
        border-color: #177ddc !important;
        box-shadow: 0 0 0 2px rgba(23, 125, 220, 0.2);
      }
    }
  }
}
