.profile-svg-wrapper {
  width: 100%;
  height: auto;
  max-width: 100%;
  overflow: hidden;
  margin: 10px 0;
  padding: 10px 0 20px 0;
}

.profile-svg {
  width: 100%;
  height: auto;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
