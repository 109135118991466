@import "../../constants.scss";

.terminal {
  margin-top: 0;
  background-color: #000000;
  color: #dcdcdc;
  padding: 10px;
  border-radius: 5px;
  overflow-x: auto;
  max-height: 240px;
  overflow-y: auto;
  max-width: 100%;
  word-wrap: break-word;
  white-space: pre-wrap;
}

.danger {
  color: #dc3545;
}

.terminal-label {
  font-family: $CODE_FONT_FAMILY;
}
