@import "./constants.scss";
@import "./components/CreditControls.scss";

:root {
  color-scheme: dark;
}

html {
  overscroll-behavior: none;
}

body {
  margin: 0;
  background-color: $PERIPHERAL_CONTENT_BACKGROUND_COLOR;
  font-family: $TEXT_FONT_FAMILY;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

* > ::selection {
  background: #646464;
}

.session-full-height-layout.session-chat-layout {
  height: auto;
}

.session-chat-layout {
  background-color: $MAIN_CONTENT_BACKGROUND_COLOR;
  height: calc(100svh - $APP_HEADER_HEIGHT);
  justify-content: space-between;
}

.nl-text-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  background-color: $INPUT_BACKGROUND_COLOR;
  border-radius: 6px;
  padding: 15px;
  border: 1px solid #424242;
  cursor: text;
}

.nl-text-container:focus-within {
  border-color: grey;
}

.solve-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  margin-top: 8px;
}

.solve-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 14px;
  font-weight: 600;
}

.prompt-limit-exceeded-warning {
  color: #ff5e5e;
  font-size: 12px;
}

.nl-text-preview {
  max-height: 264px;
  overflow: auto;
}

.nl-text-input {
  padding: 0;
  border: none;
  box-shadow: none;
  border-radius: 0px;
  background-color: transparent !important;
}

.nl-text-input:hover {
  border: none;
  box-shadow: none;
}

.nl-text-input:focus {
  border: none;
  box-shadow: none;
}

.nl-text-input:focus-visible {
  border: none;
  box-shadow: none;
  outline: none;
}

.nl-text-preview-placeholder {
  color: #4f4f4f;
  height: 22px;
  cursor: text;
  user-select: none;
}

.ant-collapse-header {
  background-color: #242424;
  border-radius: 8px 8px 0 0 !important;
  padding: 8px 12px !important;
  gap: 4px;
  align-items: center !important;
}

.ant-collapse-header:hover {
  background-color: #242424;
}

.ant-collapse-expand-icon {
  padding-inline-end: 0 !important;
  margin-inline-start: 0 !important;
}

.change-set-stats {
  display: flex;
  align-items: center;
  gap: 8px;
}

.header-summary-lines {
  font-size: 12px;
  font-family: $CODE_FONT_FAMILY;
  word-break: keep-all;
}

.header-summary-add {
  color: #00ff00;
}

.header-summary-delete {
  color: #ff0000;
}

.header-summary-files {
  font-size: 12px;
  white-space: nowrap;
  word-break: keep-all;
}

.header-summary {
  font-size: 12px;
  display: flex;
  gap: 4px;
  align-items: center;
}

.header-summary .copy-button {
  display: none;
}

.ant-collapse-header:hover .copy-button,
.ant-card-head:hover .copy-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  font-size: 12px;
  color: grey;
}

.header-summary .copy-button:hover {
  background-color: #363535;
}

// Hide hover-to-copy button on touch devices.
@media (hover: none) {
  .copy-button {
    display: none !important;
  }
}

.small-button-text {
  font-size: 12px;
}

.ant-collapse-header-text:has(.header-summary) {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.header-button-row {
  display: flex;
  gap: 8px;
}

.header-extra-stats-actions {
  display: flex;
  gap: 4px;
  flex-direction: column;
  align-items: flex-end;
}

.turn-extra-container {
  display: flex;
  gap: 8px;
}

.diff-header-path {
  font-size: 12px;
  font-family: $CODE_FONT_FAMILY;
  font-weight: 600;
  color: lightgray;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  /* The rightmost characters are the most important for identifying the file,
  so we want to show them first if there's overflow. */
  direction: rtl;
}

.diff-header-path-rename-container {
  display: flex;
  gap: 8px;
}

.diff-header-badge {
  font-size: 12px;
  font-family: $CODE_FONT_FAMILY;
  padding: 0px 4px;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
}

.diff-header-add-badge {
  color: #79b779;
  border-color: #79b779;
  background-color: #324d2f;
}

.diff-header-delete-badge {
  color: #c37779;
  border-color: #c37779;
  background-color: #542b2b;
}

.diff-header-rename-badge {
  color: lightgray;
  border-color: lightgray;
  background-color: #424242;
}

.diff-card-container {
  margin-bottom: 10px;
}

.diff-card .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.diff-card .ant-card-head {
  padding: 8px 12px;
  min-height: unset;
  background-color: #242424;
}

.diff-card > .ant-card-body {
  padding: 0;
}

.solver-header {
  padding: 0 10px;
  background-color: $PERIPHERAL_CONTENT_BACKGROUND_COLOR;
  border-bottom: 1px solid #424242;
  height: $APP_HEADER_HEIGHT;
  min-height: $APP_HEADER_HEIGHT;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-action-bar {
  display: flex;
  gap: 4px;
  align-items: center;
}

.header-action-bar-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

@media (min-width: $SMALL_SCREEN_WIDTH) {
  .header-action-sider-trigger {
    display: none;
  }
}

@media (max-width: $SMALL_SCREEN_WIDTH) {
  .header-action-bar-buttons .anticon {
    font-size: 0.9em !important;
  }

  .header-action-sider-trigger {
    display: unset;
  }
}

.solver-logo {
  cursor: pointer;
}

@media (min-width: $SMALL_SCREEN_WIDTH) {
  .solver-logo-large {
    display: block;
  }
  .solver-logo-small {
    display: none;
  }
}

@media (max-width: $SMALL_SCREEN_WIDTH) {
  .solver-logo-small {
    display: block;
  }
  .solver-logo-large {
    display: none;
  }
}

.notification-badge > .ant-badge-dot {
  top: 2px;
  right: 2px;
}

.repo-icon {
  max-width: 100%;
  border-radius: 6px;
  border: 1px solid #424242;
  flex-shrink: 0;
}

.repo-card {
  display: flex;
  align-items: center;
  gap: 8px;
}

.repo-card-text-container {
  max-width: 160px;
  min-width: 0;
}

.repo-card-title {
  margin: 0;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  font-weight: 600;
  color: #ffffff;
}

@media (max-width: $SMALL_SCREEN_WIDTH) {
  .repo-card-text-container {
    max-width: 200px;
  }

  .repo-card-title {
    font-size: 12px;
  }
}

@media (max-width: 450px) {
  .repo-card-text-container {
    max-width: 150px;
  }
}

@media (max-width: 370px) {
  .repo-card-text-container {
    max-width: 50px;
  }
}

@media (max-width: 250px) {
  .repo-card-text-container {
    display: none;
  }
}

.repo-card-org {
  font-size: 12px;
  color: #a0a0a0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-weight: 600;
}

.repo-card-demo-badge {
  font-size: 10px;
  font-weight: 600;
  color: #a0a0a0;
  padding: 2px 2px;
  border: 1px solid #a0a0a0;
  border-radius: 6px;
  display: inline;
  position: relative;
  bottom: 5px;
}

.send-report-form-submit {
  display: flex;
  align-items: center;
  gap: 1em;
}

.send-report-disclaimer {
  font-size: 12px;
  color: darkgrey;
}

.faq-modal {
  background-color: $MAIN_CONTENT_BACKGROUND_COLOR;
  color: $DIFF_TEXT_COLOR;
  font-family: $TEXT_FONT_FAMILY;
  border-radius: 8px;
  max-height: 70vh;
}

.faq-modal p {
  margin: 0;
  line-height: 1.5;
}

.terms-and-conditions-checkbox {
  margin-right: 8px;
}

.terms-and-conditions-button-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;
}

.terms-and-conditions-button {
  margin-bottom: 10px;
}

.notification-list {
  padding: 0;
  list-style: none;
}

.notification-list-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 10px;
}

.notification-list-item:last-child {
  margin-bottom: 0;
}

.notification-list-item-label {
  display: flex;
  gap: 8px;
  align-items: center;
  color: grey;
}

.app-menu-trigger {
  font-size: 1.5em;
  height: 48px;
}

.app-menu-trigger > .ant-badge {
  font-size: 1em;
}

.app-menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  gap: 10px;
}

.app-menu-list {
  list-style: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
}

.app-menu-list-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px;
}

.app-menu-list-item:hover {
  background-color: rgba(255, 255, 255, 0.08);
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

@media (min-width: $SMALL_SCREEN_WIDTH) {
  .app-menu-list-item-hidden {
    display: none;
  }
}
