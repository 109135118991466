@import "../constants.scss";

.sessions-sider-drawer {
  padding: 0;
}

.sessions-sider-container {
  position: relative;
  height: calc(100svh - $APP_HEADER_HEIGHT);
}

.sider {
  height: calc(100svh - $APP_HEADER_HEIGHT);
  overflow-y: hidden;
  overflow-x: hidden;
  border-right: 1px solid #424242;
  background-color: $PERIPHERAL_CONTENT_BACKGROUND_COLOR !important;
}

.new-session-button-container {
  position: absolute;
  top: 10px;
  right: 15px;
  z-index: 1000;
  transition: all 0.2s;
}

.new-session-button-container.collapsed {
  right: 4px;
}

.sider-collapsed {
  padding: 0;
}

.sider-expanded {
  overflow-y: auto;
}
