@import "../../constants.scss";

.coverage-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 5px;
}
.coverage-table th,
.coverage-table td {
  border: none;
  padding: 0px;
  text-align: left;
}
.coverage-table th {
  background-color: #f2f2f2;
}
.coverage-row:hover {
  background-color: #333;
}
.coverage-arrow {
  font-family: $CODE_FONT_FAMILY;
  width: 2%;
}
.coverage-definition {
  font-family: $CODE_FONT_FAMILY;
  width: 30%;
  text-overflow: ellipsis;
  overflow: hidden;
}
.coverage-coverage {
  font-family: $CODE_FONT_FAMILY;
  width: 7%;
}
.coverage-bar {
  overflow: hidden;
  background-color: #1e1e1e;
  border: 1px solid #333;
  font-family: "Courier New", Courier, monospace;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.coverage-bar-inner {
  height: 20px;
}
.coverage-bar-inner.great {
  background-color: #1a9850;
}
.coverage-bar-inner.good {
  background-color: #66bd63;
}
.coverage-bar-inner.ok {
  background-color: #ffffbf;
}
.coverage-bar-inner.bad {
  background-color: #fdae61;
}
.coverage-bar-inner.terrible {
  background-color: #f46d43;
}
.coverage-bar-inner.zero {
  background-color: #d73027;
}
