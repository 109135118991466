.solution-review-container {
  position: relative;
}

.solution-review-icon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.solution-review-header {
  margin-top: 0;
  margin-bottom: 2px;
}

.solution-review-suggestions {
  margin-top: 20px;
  margin-bottom: 20px;
}

.solution-review-suggestion-button {
  display: block;
  margin-bottom: 10px;
}
