.planning-view-content {
  flex-direction: column;
  height: 100%;
  background: var(--main-content-background-color);
  overflow: auto;
  padding: 16px;
}

.planning-view-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 24px;
}
