.new-repo-wizard-options {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  padding: 16px;
}

.new-repo-wizard-option {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px;
}

.new-repo-wizard-option-description {
  margin: 12px 0 24px;
  color: rgba(255, 255, 255, 0.65) !important;
}

.new-repo-wizard-hint {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.45);
  margin: 8px 0;
}
