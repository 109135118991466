@import "../constants.scss";

/* Layout */
.session-content:has(.changes-layout) {
  padding: 0;
}

.scrollbar-gutter-stable-both:has(.changes-layout) {
  scrollbar-gutter: unset;
}

.changes-layout {
  height: 100%;
  background-color: $MAIN_CONTENT_BACKGROUND_COLOR;
  display: flex;
  flex-direction: row;
}

/* Sidebar Container */
.changes-sider-container {
  position: relative;
}

/* Sidebar */
.changes-sidebar {
  background-color: $MAIN_CONTENT_BACKGROUND_COLOR !important;
  margin-right: 15px;
  margin-left: 15px;
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (max-width: 900px) {
  .changes-sidebar {
    display: none;
  }

  .changes-content {
    padding-left: 32px;
  }
}

.changes-sidebar.ant-layout-sider-collapsed {
  border-right: none;
}

/* Content */
.changes-content {
  overflow: hidden;
  height: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 22px;
}

/* Loading */
.changes-loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #ffffff;
}

/* No Changes Visible */
.no-changes-visible {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  color: #a0a0a0;
}

/* Floating Refresh Button */
.floating-refresh-button {
  position: sticky;
  top: 0;
  float: right;
  margin-right: 22px;
  z-index: 100;
}

.floating-refresh-button .ant-btn {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

/* Changes View Header */
.changes-view-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.changes-view-header-group {
  display: flex;
  align-items: center;
  gap: 16px;
}

.changes-loading-icon {
  font-size: 24px;
}
