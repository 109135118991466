.message {
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  position: relative;
}

.message-collapsed {
  transition: max-height 0.1s ease;
  overflow: hidden;
  position: relative;
}

.message-collapsed::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 20px;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.75));
  pointer-events: none;
  z-index: 1;
}

.message-indicator {
  position: absolute;
  bottom: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(255, 255, 255, 0.85);
  font-size: 16px;
  opacity: 0;
  transition: opacity 0.2s ease;
  z-index: 2;
}

.message:hover .message-indicator {
  opacity: 1;
}

.message-indicator:hover {
  transform: scale(1.05);
}

.user-message {
  background-color: #3f5d70;
}

.agent-message {
  background-color: #2e383e;
}
