@import "../constants.scss";

.session-card {
  background-color: #161616;
  border-color: #424242;
  cursor: pointer;
  transition: all 0.2s ease;
}

.session-card:hover:not(.session-card-active) {
  border-color: $SOLVER_BLUE_HOVER;
}

.session-card-active {
  border-color: $SOLVER_BLUE !important;
  background-color: #1a1f23 !important;
}

.session-card-active:not(.session-card-action) {
  cursor: default !important;
}

.session-card-body {
  display: grid;
  grid-template-columns: 28px 150px 28px;
  gap: 5px;
  justify-content: space-between;
  align-items: center;
}

.session-card-hover {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  border-radius: 5px;
  font-size: 1em;
  color: grey;
  cursor: pointer;
  transition: all 0.2s;
}

.session-card-hover-disabled {
  cursor: not-allowed;
}

.session-card-hover:hover {
  background-color: #363535;
}

.session-card-action {
  color: $SOLVER_BLUE;
  cursor: pointer;
}

.session-card-title,
.session-card-branch-name {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: grey;
}

.session-card-title-area {
  margin: 5px 0;
  max-height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.session-card-title-content {
  flex: 1;
  min-width: 0;
}

.session-status-icon {
  font-size: 1.75em;
  color: grey;
  justify-content: flex-end;
}

.session-status-icon-loading {
  color: whitesmoke;
}

.session-card-description {
  display: flex;
  flex-direction: column;
  word-break: break-all;
  color: grey;
}

.session-card-author {
  display: inline-flex;
  gap: 8px;
}

.session-card-author-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid grey;
}

.session-card-branch-area {
  display: flex;
  gap: 4px;
  align-items: center;
  color: grey;
  font-size: 11px;
}

.session-card-branch-name {
  font-family: $CODE_FONT_FAMILY;
  font-weight: normal;
  display: block;
}

.session-card-timestamp {
  font-size: 9px;
  word-break: break-word;
}

.ant-card-meta-title:has(.session-card-title) {
  margin-bottom: 0 !important;
}

.session-card-title-text-active {
  color: $SOLVER_BLUE;
  font-weight: 600;
}

.session-card-title-text-inactive {
  color: grey;
}

.session-card-owner-actions {
  display: flex;
  align-items: center;
  gap: 8px;
}

.session-card-title-area .session-card-owner-actions {
  gap: 8px;
}

.session-card-owner-actions .session-card-action-button {
  color: #919191;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 5px;
  transition: all 0.2s;
}

.session-card-owner-actions .session-card-action-button:hover {
  background: #363535;
}
