.api-integrations-tab {
  width: 100%;

  .slack-integration {
    .ant-typography {
      &.ant-typography-h4 {
        margin-bottom: 8px;
      }

      &.ant-typography-paragraph {
        margin-bottom: 16px;
      }
    }

    .ant-collapse {
      margin-bottom: 16px;

      .ant-collapse-content-box {
        padding: 8px 0;
      }
    }
  }
}
