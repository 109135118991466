@import "../constants.scss";

$SESSION_CARD_HEIGHT: 123px;
$SESSIONS_LIST_HEADER_HEIGHT: 52px;
$TIME_BUCKET_HEADER_HEIGHT: 40px; /* 8px top + 8px bottom + 24px line height */

.new-session-form {
  display: flex;
  margin: 0 10px;
  margin-bottom: 10px;
}

.session-filter-list {
  min-width: 100px;
}

.session-list-header {
  margin: 10px 15px;
  display: flex;
  gap: 6px;
  max-height: $SESSIONS_LIST_HEADER_HEIGHT;
  padding-right: 40px; /* Space for the create session button */
}

.session-list-search {
  flex: 1;
  width: calc(100% - 40px); /* Account for the button width */
}

/* Add space below the header to account for the trigger */
.sessions-list {
  padding-top: 32px; /* Match trigger height */
}

.time-bucket-header {
  font-size: 1em;
  color: #666;
  padding: 8px 15px;
  background-color: transparent;
  border-bottom: none;
  height: auto;
  line-height: 24px;
  font-weight: 500;
  cursor: default;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.session-list-item {
  padding-left: 15px;
  padding-right: 8px;
  max-height: $SESSION_CARD_HEIGHT;
  list-style: none;
  transition: transform 0.1s ease-out;
}

@media (max-width: $SMALL_SCREEN_WIDTH) {
  .time-bucket-header {
    width: 282px !important;
  }

  .session-list-item {
    width: 282px !important;
  }
}

/* Firefox doesn't support ::-webkit-scrollbar*, so we explicitly set the
right padding where the gutter will appear.  */
@supports not selector(::-webkit-scrollbar) {
  .session-list-item {
    padding-right: 15px;
  }
}

.session-list-item-active {
  pointer-events: auto !important;
}
