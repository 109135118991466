$INPUT_BACKGROUND_COLOR: #141414;
$TEXT_FONT_FAMILY: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
  "Droid Sans", "Helvetica Neue", sans-serif;
$CODE_FONT_FAMILY: ui-monospace, SFMono-Regular, "SF Mono", Menlo, Consolas, "Liberation Mono", monospace;
$SOLVER_PINK: #d90368;
$SOLVER_BLUE: #409ad4;
$SOLVER_BLUE_HOVER: #5fa4cc;
$PERIPHERAL_CONTENT_BACKGROUND_COLOR: #181818;
$MAIN_CONTENT_BACKGROUND_COLOR: #212121;
$APP_HEADER_HEIGHT: 75px;
$DIFF_TEXT_FONT_SIZE: 12px;
$DIFF_TEXT_COLOR: #e2e2e2;

$SMALL_SCREEN_WIDTH: 675px;
