@import "../constants.scss";

.session-content {
  margin: 0 0 auto 0;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 10px;
  padding-right: 10px;
  height: 100%;
}

@supports not (scrollbar-gutter: stable) {
  .session-content {
    padding-left: 18px;
  }
}

.no-active-session {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $MAIN_CONTENT_BACKGROUND_COLOR;
  height: calc(100svh - $APP_HEADER_HEIGHT);
}

.no-active-session-text {
  color: darkgrey;
}

.new-session-message {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 20px;
  padding: 0 10px;
}

.new-session-solver-logo {
  width: 100px;
  height: 100px;
  background-color: #505050;
  mask-image: url("../images/solver_icon_reverse_transparent.png");
  mask-size: contain;
  mask-repeat: no-repeat;
  mask-position: center;
}

.welcome-message-container {
  font-family: $CODE_FONT_FAMILY;
  font-size: 1.5em;
  color: #505050;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.welcome-message-container-small {
  font-size: 1.25em;
}

.welcome-message-line {
  margin: 0;
  line-height: 1.4;
  user-select: none;
  cursor: default;
}

.active-session-title-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  padding: 10px;
  border-bottom: 1px solid #424242;
}

.active-session-title-bar .ant-tooltip-wrapper {
  line-height: 1;
}

.active-session-title-container {
  color: #dedede;
  font-size: 18px;
  font-weight: 700;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 8px;
  flex-shrink: 100;
}

.active-session-title {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.active-session-title-edit {
  max-width: 200px;
}

.session-title-edit-trigger {
  color: #909090;
  font-size: 14px;
}

.session-title-edit-trigger:hover {
  color: #dedede;
}

.active-session-controls-container {
  display: flex;
  gap: 10px;
}

.active-session-controls {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 0;
  white-space: nowrap;
  flex-wrap: wrap;
}

.active-session-controls-divider {
  border-left: 1px solid #424242;
}

.active-session-controls .ant-radio-group {
  display: flex;
  flex-wrap: wrap;
}

.active-session-controls-radio {
  color: #b4b4b4;
}

.active-session-controls-button {
  height: 32px !important;
  box-shadow: none;
  border-radius: 6px;
  background-color: #141414;
  cursor: pointer;
  color: #b4b4b4;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
}

.active-session-controls-button > button {
  @extend .active-session-controls-button;
}

.active-session-controls-button-small {
  padding: 8px !important;
  border: 1px solid #424242;
}

@media (max-width: $SMALL_SCREEN_WIDTH) {
  .active-session-title-bar {
    justify-content: center;
  }

  .active-session-controls-button {
    font-size: 0.8em;
  }

  .active-session-controls-button > button {
    font-size: 0.9em;
  }

  .active-session-controls-button-small {
    width: unset !important;
  }

  .active-session-controls-button-small .anticon {
    font-size: 1em !important;
  }
}

.input-container {
  padding: 0 15px;
  padding-bottom: 10px;
}

.push-to-remote-modal-title,
.push-to-remote-modal-loading-icon {
  text-align: center;
}

.push-to-remote-modal-loading-icon {
  font-size: 2em;
}

.push-to-remote-modal-link {
  color: $SOLVER_BLUE;
}

.push-to-remote-modal-loading-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.copy-patch-modal-copy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.copy-patch-modal-instructions {
  margin-top: 16px;
  border-top: 1px solid #424242;
  padding-top: 16px;
}

.copy-patch-modal-instructions-pre pre {
  background-color: #141414;
  padding: 12px;
  border-radius: 4px;
  margin: 8px 0;
}
