@import "../../constants.scss";

.relevant-files-table {
  background-color: #141414;
  padding: 10px;
  border-radius: 6px;
  width: 100%;
  table-layout: fixed;
}

.relevant-files-table-cell {
  padding: 2px;
}

.relevant-files-table-extension-cell {
  width: 50px;
}

.relevant-files-table-path-cell {
  width: 90%;
  font-size: 12px;
  font-weight: 600;
  font-family: $CODE_FONT_FAMILY;
  width: auto;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.relevant-files-summary {
  font-size: 12px;
  margin-left: 3px;
}
