@import "../../constants.scss";

.agent-message:has(.linter-card),
.linter-error-message {
  background-color: transparent;
}

.linter-card {
  display: flex;
  align-items: center;
  margin-left: 16px;
  color: #999;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.linter-card > .ant-card-body {
  padding: 0;
}

.linter-card-content {
  transition: max-height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.lint-error {
  font-family: $CODE_FONT_FAMILY;
  font-size: 0.9em;
  display: flex;
  align-items: center;
  width: 100%;
}

.lint-error-content {
  display: flex;
  width: 100%;
  gap: 32px;
}

.lint-error-filename {
  flex: 1;
}

.lint-error-message {
  flex: 1.5;
}

.lint-error-link {
  flex-shrink: 0;
}

.linter-card.summary .linter-card-content {
  max-height: 50px;
}

.linter-card.details .linter-card-content {
  max-height: 512px;
  overflow-y: auto;
}

.linter-card-content::after {
  content: "Show";
  position: absolute;
  left: 0;
  bottom: -10px;
  font-size: 0.8em;
  color: #888;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.linter-card.details .linter-card-content::after {
  content: "Hide";
}

.linter-card:hover .linter-card-content::after {
  opacity: 1;
}

.linter-card.empty {
  cursor: default;
}

.linter-card.empty .linter-card-content::after {
  content: "";
}
