@import "../constants.scss";

.ant-tour {
  --tour-primary-color: $SOLVER_BLUE;
}

.ant-tour-inner {
  padding: 16px 20px;
  transition: all 0.3s ease;
}

.ant-tour {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.ant-tour-arrow {
  background-color: #2a2a2a;
}

.ant-tour-inner .ant-tour-title {
  color: $SOLVER_BLUE;
  font-weight: bold;
  font-size: 18px !important;
  font-family: $CODE_FONT_FAMILY;
  margin-bottom: 8px;
}

.ant-tour-inner .ant-tour-description {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 4px;
}

.ant-tour-buttons {
  position: relative;
  z-index: 1001;
  margin-top: 12px;
}

.ant-tour-inner {
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.ant-tour-next-btn {
  background-color: $SOLVER_BLUE;
  border-color: $SOLVER_BLUE;
  font-size: 14px;
  padding: 6px 12px;
  height: auto;
  transition: all 0.2s ease;
}

.ant-tour-next-btn:hover {
  background-color: $SOLVER_BLUE_HOVER;
  border-color: $SOLVER_BLUE_HOVER;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Tour mask with smooth transition */
.ant-tour-mask {
  background-color: rgba(0, 0, 0, 0.05) !important;
  transition: background-color 0.3s ease;
}

.ant-tour-mask:hover {
  background-color: rgba(0, 0, 0, 0.08) !important;
}
