@import "../../constants.scss";

.commit-label {
  display: flex;
  flex-direction: column;
  margin-right: 4px;
}

.commit-button {
  border: 1px solid grey;
  cursor: pointer;
  background-color: #252e34;
  color: white;
  font-family: $CODE_FONT_FAMILY;
  display: flex;
  align-items: center;
}

.commit-button:hover {
  color: darkgrey;
  border-color: darkgrey;
}

.ant-btn-sm.commit-button {
  height: 24px;
  font-size: 12px;
}

.commit-message {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  font-weight: 600;
}

.commit-author-date {
  color: grey;
}

.commits-collapse {
  margin-top: 10px;
}
