/* Override ant-design sider trigger styles */
.ant-layout-sider-trigger,
.ant-layout-sider-zero-width-trigger {
  background: transparent !important;
  height: 32px !important;
  line-height: 32px !important;
  transition: background-color 0.3s;
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
  width: 15px !important;
  right: 0 !important;
  left: unset !important;
}

.ant-layout-sider-trigger:hover,
.ant-layout-sider-zero-width-trigger:hover {
  background: rgba(24, 144, 255, 0.1) !important;
}

.ant-layout-sider-trigger:hover .sider-trigger-icon,
.ant-layout-sider-zero-width-trigger:hover .sider-trigger-icon {
  color: #1890ff;
}

.sider-trigger-icon {
  font-size: 1em;
  font-weight: bold;
  transition: color 0.3s;
}

/* Additional overrides for zero-width trigger that don't conflict */
.ant-layout-sider-zero-width-trigger {
  inset-inline-end: -15px !important;
  z-index: 1;
  color: inherit;
  border-radius: 0 !important;
  font-size: 1em !important;
}
