@import "../constants.scss";

.repo-dropdown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #424242;
  border-radius: 6px;
  gap: 8px;
  padding: 0 10px;
  user-select: none;
  cursor: pointer;
  height: 48px;
  transition: 0.2s ease;
}

.repo-dropdown:hover {
  border-color: $SOLVER_BLUE_HOVER;
}

.repo-dropdown-overlay {
  max-height: 400px;
  background-color: #1f1f1f;
}

.repo-dropdown-overlay .ant-dropdown-menu-item-group-title {
  color: #888;
  font-weight: bold;
  padding: 8px 12px;
}

.repo-dropdown-overlay .ant-dropdown-menu-item-group-list {
  margin: 0;
  padding: 0;
}

.repo-dropdown-overlay .ant-dropdown-menu-item {
  padding-left: 24px;
}

.repo-dropdown-overlay .ant-dropdown-menu-item-divider {
  margin: 4px 0;
}

.repo-dropdown-repo-label {
  font-size: 14px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
}

// Antd want to make the menu item flex, but it sits in a li, so this will
// reset it to list-item so .repo-dropdown-repo-label can do the work to make
// a flex layout.
.ant-dropdown-menu-item:has(.repo-dropdown-repo-label) {
  display: revert !important;
}

.repo-dropdown-repo-label-inactive {
  color: grey;
}

.repo-dropdown-repo-label-inactive-badge {
  border: 1px solid grey;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 600;
  padding: 0 3px;
}

.repo-dropdown-add-repo-label {
  font-size: 14px !important;
  display: flex;
  gap: 4px;
  color: darkgrey;
  width: 100%;
  padding: 5px 12px;
}

.repo-dropdown-add-repo-label:hover {
  color: inherit;
}

.ant-dropdown-menu-item:has(.repo-dropdown-add-repo-label) {
  padding: 0 !important;
}

.repo-dropdown-non-selectable-label {
  display: flex;
  gap: 8px;
  width: 100%;
  margin-inline-end: 0 !important;
}

.ant-dropdown-menu-item:has(.repo-dropdown-non-selectable-label):hover {
  background-color: inherit !important;
  cursor: default;
}

.repo-dropdown-tabs {
  display: flex;
  gap: 4px;
  width: 100%;
  margin-inline-end: 0 !important;
}

.repo-dropdown-tabs-divider {
  height: 28px;
}

.repo-dropdown-tab-button {
  border: none;
  border-bottom: 1px solid transparent;
  background-color: transparent;
  box-shadow: none;
  color: #a0a0a0;
  font-weight: 600;
  border-radius: 0;
  padding: 4px 8px;
  font-size: 13px;
  width: 100%;
}

.repo-dropdown-tab-button:hover {
  border-bottom: 1px solid $SOLVER_BLUE_HOVER;
}

.repo-dropdown-tab-button:focus-visible {
  outline: none !important;
}

.repo-dropdown-tab-button-active {
  border-bottom: 1px solid $SOLVER_BLUE;
  color: $SOLVER_BLUE;
}

.ant-dropdown-menu-item:has(.repo-dropdown-tab-button) {
  background-color: inherit !important;
  cursor: default;
}
