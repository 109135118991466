@supports (scrollbar-gutter: stable) {
  .scrollbar {
    overflow: hidden !important;
  }
}

/* TODO: Safari started supporting scrollbar-gutter in 18.2, but it's not widely used
yet according to caniuse. We can remove this and make the scrollbar hidden when not
hovered once Safari 18.2 is more widely used. */
@supports not (scrollbar-gutter: stable) {
  .scrollbar {
    overflow: auto !important;
  }
}

.scrollbar:hover {
  overflow: auto !important;
}

.scrollbar::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #6b6b6b;
  border-radius: 5px;
}

.scrollbar-gutter-stable {
  scrollbar-gutter: stable;
}

.scrollbar-gutter-stable-both {
  scrollbar-gutter: stable both-edges;
}
