@import "../constants.scss";

.prompt-suggestions-container {
  display: flex;
  gap: 10px;
  flex-direction: column;
  max-width: 500px;
}

.prompt-suggestion {
  font-family: $CODE_FONT_FAMILY;
  font-size: 12px;
  border: none;
  background-color: rgb(43, 43, 43);
  color: #c8c8c8;
  white-space: normal;
  height: auto;
  border-radius: 10px;
  padding: 8px;
  align-self: flex-start;
  text-align: left;
}

.prompt-suggestion-text {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.prompt-suggestion:hover {
  background-color: #323232;
}
