@import "../constants.scss";

.message-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.message-group-avatar-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
}

.message-group-avatar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 4px;
}

.message-group-avatar-name {
  color: grey;
  font-weight: 600;
}

.message-group-avatar-image {
  border-radius: 50%;
  border: 1px solid grey;
  width: 24px;
  height: 24px;
}

@media (max-width: $SMALL_SCREEN_WIDTH) {
  .message-group-avatar-image {
    width: 20px;
    height: 20px;
  }
}

.message-group-collapsible-messages {
  /* https://keithjgrant.com/posts/2023/04/transitioning-to-height-auto/ */
  display: grid;
  transition: grid-template-rows 0.2s ease-out;
}

.message-group-collapsible-messages-collapsed {
  grid-template-rows: 0fr;
}

.message-group-collapsible-messages-expanded {
  grid-template-rows: 1fr;
}

.message-group-collapsible-messages-inner {
  overflow: hidden;
}

.message-group-header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: -5px;
  margin-bottom: 5px;
}

.message-group-header-button {
  border: none;
  background-color: transparent;
  box-shadow: none;
  color: grey;
}

@media (max-width: $SMALL_SCREEN_WIDTH) {
  .message-group-header-button {
    padding: 4px 0;
  }
}

.message-group-header::after {
  content: "";
  height: 1px;
  background-color: grey;
  flex-grow: 1;
}
