.api-key-delete-modal {
  // Prevent event propagation on the modal wrapper
  .ant-modal-wrap {
    pointer-events: auto !important;
  }

  // Ensure the modal content also prevents propagation
  .ant-modal-content {
    pointer-events: auto !important;
  }
}

.api-key-management {
  .api-key-display {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    background: #fafafa;
    padding: 16px;
    border-radius: 6px;
    border: 1px solid #f0f0f0;

    .api-key-text {
      flex: 1;
      font-family: "SFMono-Regular", Consolas, "Liberation Mono", Menlo, Courier, monospace;
      font-size: 14px;
      word-break: break-all;
      color: #595959;
      letter-spacing: 0.5px;
    }
  }
  width: 100%;

  .api-key-header {
    display: flex;

    .ant-typography {
      color: #e2e2e2;
      margin: 0;
    }
  }

  .api-key-table {
    .ant-table {
      padding: 8px;
      background-color: #262626;
      border: 1px solid #393939;
      overflow: hidden;
      table-layout: fixed;

      .ant-table-thead > tr > th {
        white-space: normal;
        word-break: break-word;
        line-height: 1.4;
        padding: 8px;
      }
    }
  }
}
