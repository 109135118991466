@import "../../constants.scss";

.bisect-revisions-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1.5em;
  margin-bottom: 1.5em;
}
.bisect-revisions-table th,
.bisect-revisions-table td {
  border: none;
  text-align: left;
}

.bisect-revision-latest {
  border: 1px solid #f5c6cb;
}
.bisect-revision-latest-marker {
  font-family: $CODE_FONT_FAMILY;
  font-weight: bold;
  width: 0;
  padding-left: 10px;
  padding-right: 5px;
}
.bisect-revision-commit {
  font-family: $CODE_FONT_FAMILY;
  font-weight: bold;
  width: 5%;
  padding: 0 5px;
}
.bisect-revision-state {
  font-family: $CODE_FONT_FAMILY;
  width: 6%;
  padding: 0 5px;
}
.bisect-revision-message {
  font-family: $CODE_FONT_FAMILY;
  width: 85%;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  padding-left: 10px;
}
.bisect-revision-state-untested {
  font-weight: lighter;
}
.bisect-revision-state-good {
  color: green;
}
.bisect-revision-state-bad {
  color: red;
}
.bisect-breaking-commit {
  margin-top: 25px;
  padding: 10px;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
}

.bisect-revision-pruned {
  opacity: 0.5;
  animation: fadeOut 2.5s forwards;
}
@keyframes fadeOut {
  0% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
