.audio-recorder.recording {
  position: relative !important;
}

/* Hacks to change icons with react-audio-voice-recorder. Currently, this package doesn't natively support this. */
/* Position and style buttons */
.audio-recorder.recording::before {
  content: "\2191" !important;
  /* Up arrow for submit */
  position: absolute !important;
  top: 50% !important;
  left: 16px !important;
  transform: translateY(-50%) !important;
  color: black !important;
  font-size: 28px !important;
  z-index: 10 !important;
}

/* Hide original buttons but keep their clickable areas */
.audio-recorder.recording img {
  opacity: 0 !important;
  position: relative !important;
  z-index: 20 !important;
}

/* Add X symbol for discard button */
.audio-recorder.recording::after {
  content: "\2716" !important;
  /* X symbol */
  position: absolute !important;
  top: 50% !important;
  right: 16px !important;
  transform: translateY(-50%) !important;
  color: black !important;
  font-size: 20px !important;
  font-weight: bold !important;
}

.custom-audio-recorder.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.transcribing-loader {
  color: white;
  margin-right: 8px;
  font-size: 24px;
}
