.execution-message-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.message-cta-container {
  display: flex;
  justify-content: flex-end;
}
