.filter-list {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.filter-list-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;
  user-select: none;
}

.filter-list-item:hover {
  cursor: pointer;
  background-color: rgb(29, 161, 242, 0.1);
  border-radius: 4px;
}

.filter-list-item-icon {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid grey;
}

.filter-list-item-label {
  font-size: 14px;
  color: rgb(197, 209, 222);
}
