@import "../constants.scss";

.new-session-loading-branches {
  padding: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.new-session-dropdown-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

@media (max-width: $SMALL_SCREEN_WIDTH) {
  .new-session-dropdown-wrapper {
    max-width: 250px;
  }
}

.new-session-instructions {
  font-size: 12px;
  color: grey;
}

.new-session-dropdown-input {
  margin-bottom: 16px;
}

.new-session-dropdown-menu {
  background-color: transparent;
  color: #fff;
  padding: 4px 0;
  border-inline-end: none !important;
  max-height: 400px;
  overflow-y: auto;
}

.new-session-dropdown-menu .ant-menu-item {
  color: #fff;
  padding: 5px 12px;
  display: flex;
  align-items: center;
  margin: 0;
  max-height: 32px;
  min-width: 0;
  line-height: 1.57;
}

.new-session-dropdown-menu .ant-menu-item:active {
  background-color: inherit !important;
}

.new-session-dropdown-menu-item-inner {
  display: flex;
  width: 300px;
  justify-content: space-between;
  gap: 8px;
}

.new-session-dropdown-menu-item-inner > * {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.new-session-default-branch-badge {
  border: 1px solid grey;
  color: grey;
  border-radius: 6px;
  padding: 0px 3px;
  font-size: 12px;
}
